import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';

const ImgAnchor = styled.a`
  .gatsby-image-wrapper {
    transition: all 250ms ease-in-out;
    transform: scale(1);
    filter: grayscale(1);
  }

  &:hover {
    .gatsby-image-wrapper {
      transform: scale(1.05);
      filter: grayscale(0);
    }
  }
`;

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 0.4rem 1rem;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 0.4rem 2rem;
  max-width: 100%;
  line-height: 1.4em;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;

const ProfileImage = styled.img`
  max-width: 35px;
  border-radius: 100px;
  filter: grayscale(1);
`;

type ItemProps = {
  data: {
    Text?: string,
    Author?: string,
    CreatedAt?: string,
    meta?: {
      image?: string,
      title?: string,
      description?: string,
    },
    AvatarURL?: string,
  },
  fields?: {
    localFile?: {
      childImageSharp: any,
    },
  },
};

const Item: React.SFC<ItemProps> = ({ data, fields }) => {
  const url = new URL(data.Text.match(/\bhttps?:\/\/\S+/gi).toString());

  return (
    <figure>
      {fields?.localFile?.childImageSharp ? (
        <ImgAnchor href={url.href} target="blank">
          <Img
            fluid={fields?.localFile?.childImageSharp.fluid}
            alt={data.Text}
          />
        </ImgAnchor>
      ) : null}
      <figcaption>
        <a href={url.href} target="blank">
          <Title>{(data.meta && data.meta.title) || url.host}</Title>
        </a>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '.4rem 0.4rem .6rem',
          }}
        >
          {data.AvatarURL ? (
            <div style={{ paddingRight: '1.2rem' }}>
              <ProfileImage src={data.AvatarURL} alt={data.Author} />
            </div>
          ) : null}
          <div style={{ paddingRight: '1.2rem' }}>
            {data.Author} | {format(new Date(data.CreatedAt), 'MMMM do')}
          </div>
        </div>
        <Copy>{data?.meta?.description || data.Text}</Copy>
      </figcaption>
    </figure>
  );
};
export default Item;
