import React from 'react';
import Item from 'components/inspirationgallery/item';
import MEDIA from 'helpers/mediaTemplates';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  padding: 0 4rem;
  margin: 2rem 0;

  ${MEDIA.TABLET`
    display: block;
  `};
`;

type InspirationGalleryProps = {
  items: object[],
};

const InspirationGallery: React.SFC<InspirationGalleryProps> = ({ items }) => (
  <Container>
    {items.map(item => (
      <Item {...item.node} key={item.node.id} />
    ))}
  </Container>
);
export default InspirationGallery;
