import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import InspirationGallery from 'components/inspirationgallery';
// import IOExample from 'components/io-example';
import { graphql } from 'gatsby';

const Index = ({ data }) => {
  return (
    <Layout>
      <Box>
        <Title as="h2" size="large">
          {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
        </Title>
      </Box>
      <InspirationGallery items={data.allAirtable.edges} />
      {/* <div style={{ height: '30vh' }} /> */}
      {/* <IOExample /> */}
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    allAirtable(sort: { fields: data___CreatedAt, order: DESC }) {
      edges {
        node {
          id
          data {
            Author
            Text
            CreatedAt
            AvatarURL
            meta {
              title
              image
              description
            }
          }
          fields {
            localFile {
              childImageSharp {
                fluid(maxHeight: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
